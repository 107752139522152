import React, { useState, useRef } from "react";
import Button from "../common/Button";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import Slider from "react-slick/lib/index";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from "clsx";
import CustomContainer from "../common/CustomContainer";

const slides = [
    {
        title: "Convert (Fast Swap)",
        description: "Since the coin requested by our customers is not available on popular/safe exchanges, we created a secure payment gateway.",
    },
    {
        title: "Special for the Launch",
        description: "For the launch, there will be a 50% discount on withdrawal commissions for 1 month. For example, $0.5 instead of $0.10",
    },
    {
        title: "Convert for customers",
        description: "Since the coin requested by our customers is not available on popular/safe exchanges, we created a secure payment gateway.",
    },
    {
        title: "Added WEMIX Network and Wemix coins",
        description: "Like the e-commerce module, you can get any coin you want from your customers and provide the service for them. (Example, sell premium with wemix)",
    },
    {
        title: "Convert Feature is Activated",
        description: "Your customers can quickly buy the coins we support and earn commission from it.",
    }
]

const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className={'absolute top-1/2 left-0 w-10 h-10 bg-white text-black rounded-full flex items-center justify-center hover:scale-105 -translate-x-1/2 -translate-y-1/2 z-30'}
            onClick={onClick}
        >
            <ArrowLeft size={24} />
        </button>
    );
};

const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
        <button
            className={'absolute top-1/2 right-0 w-10 h-10 bg-white text-black rounded-full flex items-center justify-center hover:scale-105 translate-x-1/2 -translate-y-1/2 z-30'}
            onClick={onClick}
        >
            <ArrowRight size={24} />
        </button>
    );
};

const HomeSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    const handleSlideChange = (index) => {
        setCurrentSlide(index);
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(index);
        }
    };

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        afterChange: (index) => setCurrentSlide(index),
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <CustomContainer id="campaigns">
            <h2 className="text-white text-2xl lg:text-4xl font-bold mb-2 max-w-2xl text-center mx-auto mt-40">Campaigns & Coins</h2>
            <p className="text-white/40 text-base lg:text-lg mb-12 max-w-2xl text-center mx-auto">We are making new campaigns and organizing events for our customers. The coins listed here are the current/soon to be added coins.</p>

            <div className="relative flex flex-col items-center justify-center text-center bg-gradient-to-r border border-white/5 to-white/5 rounded-3xl pt-4">
                <div className="flex items-center justify-center gap-x-2 mx-auto relative z-30 mb-4">
                    {slides?.map((item, index) => (
                        <button
                            key={index}
                            className={clsx("w-2 h-2 bg-white/10 rounded-full", currentSlide === index && "!bg-white !w-6")}
                            onClick={() => handleSlideChange(index)}
                        />
                    ))}
                </div>

                <Slider ref={sliderRef} {...settings} className="w-full">
                    {slides?.map((item, index) => (
                        <div key={index} className="px-4 md:px-10 lg:px-24">
                            <div className="flex flex-col md:flex-row items-center justify-between z-20 relative">
                                <div className="flex flex-col flex-1 w-full max-w-[450px] text-center md:text-left gap-y-6">
                                    <h2 className="text-2xl lg:text-4xl font-bold text-emerald-300">{item?.title}</h2>
                                    <p className="text-white/60 text-base lg:text-lg">{item?.description}</p>
                                    <Button variant="primary" className="w-fit mx-auto md:mx-0" onClick={() => window.location.href = "/contact"}>GET STARTED</Button>
                                </div>
                                <div className="flex flex-col flex-1 w-full mt-4 md:mt-12 lg:mt-24 xl:mt-0">
                                    <img
                                        src="/img/quick-crypto-campaign-2.png"
                                        alt="gold in wallet how it works"
                                        className="max-w-72 object-cover ml-auto max-lg:mr-auto rounded-2xl"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </CustomContainer>
    );
}

export default HomeSlider;