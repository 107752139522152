import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ModalManager from "../../modals/ModalManager";

const Layout = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <div className="flex flex-col min-h-screen">
                <ModalManager />

                <Navbar />
                <Outlet />
                <Footer />
            </div>
        </>
    );
};

export default Layout;