import clsx from "clsx";
import { Add } from "iconsax-react";
import { useState } from "react";

const Accordion = ({ title, subTitle, items = [], className }) => {
    const [visibleIndex, setVisibleIndex] = useState(0);

    return (
        <div className={clsx("flex flex-col w-full max-w-2xl mx-auto", className)}>
            <h1 className="font-bold text-center text-2xl">{title}</h1>
            <h2 className="font-light text-center text-xl mt-2 mb-6">{subTitle || "Frequently Asked Questions"}</h2>

            {items?.map((item, index) => (
                <div
                    className={`flex flex-col border-t border-white/5 w-full`}
                    key={'Accordion-' + index}
                >
                    <div
                        className={`flex items-center justify-between py-4 cursor-pointer transition duration-300 ${visibleIndex === index ? "text-white" : "text-white/50 hover:text-white/70"}`}
                        onClick={() => setVisibleIndex(index === visibleIndex ? null : index)}
                    >
                        <h3 className="text-lg font-medium">{`${index + 1}. ${item.title}`}</h3>

                        <button className="flex items-center justify-center w-10 h-10">
                            <Add className={`w-8 h-8 ${visibleIndex === index ? "rotate-45" : ""}`} />
                        </button>
                    </div>

                    <div className={`border-b border-white/5 -mt-4 pb-3 transition-all duration-500 ${visibleIndex === index ? "opacity-100 max-h-[750px]" : "translate-y-4 opacity-0 max-h-[0px] pointer-events-none"}`}>
                        <p className="text-base font-light leading-loose text-white/50">{item.content}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Accordion;