import * as yup from "yup";

export const firstNameSchema = yup
    .string()
    .min(2, "First name is required")
    .max(16, "First name must be at most 16 characters long");

export const lastNameSchema = yup
    .string()
    .min(2, "Last name is required")
    .max(16, "Last name must be at most 16 characters long");

export const accountTypeSchema = yup
    .string()
    .oneOf(["individual", "corporate"], "Invalid account type")
    .required("Account type is required");

export const phoneNumberSchema = yup
    .string()
    .matches(/^\+\d{1,3}\s\d{3}\s\d{3}\s\d{2}\s\d{2}$/, "Invalid phone number")
    .required("Phone number is required");

export const emailSchema = yup
    .string()
    .email("Invalid email")
    .min(6, "Email is invalid")
    .max(64, "Email must be at most 64 characters long")
    .required("Email is required");

export const passwordSchema = yup
    .string()
    .min(3, "Password must be at least 3 characters long")
    .max(24, "Password must be at most 24 characters long")
    .required("Password is required");

// not match and required
export const confirmPasswordSchema = (field) => yup
    .string()
    .oneOf([yup.ref(field), null], "Passwords must match")
    .required("Confirm password is required");

export const coinSchema = yup
    .string()
    .min(1, "Coin is required")
    .max(12, "Coin must be at most 12 characters long");

export const networkSchema = yup
    .string()
    .min(1, "Network is required")
    .max(12, "Network must be at most 12 characters long");

export const coinAddressSchema = yup
    .string()
    .matches(/^[a-zA-Z0-9]+$/, "Address is not valid")
    .min(12, "Invalid address")
    .max(32, "Invalid address")
    .required("Address is required");

export const amountSchema = yup
    .number()
    .min(0.00000001, "Amount must be greater than 0")
    .max(1000000, "Amount must be less than 1,000,000")
    .required("Amount is required");

export const withdrawSchema = yup
    .object({
        coin: coinSchema,
        network: networkSchema,
        address: coinAddressSchema,
        amount: amountSchema
    });

export const swapItemSchema = yup
    .object({
        currency: coinSchema,
        amount: amountSchema
    });

export const messageSchema = yup
    .string()
    .min(64, "Message must be at least 64 characters long")
    .max(512, "Message must be at most 512 characters long")
    .required("Message is required");

export const validations = {
    accountTypeSchema,
    emailSchema,
    passwordSchema
};