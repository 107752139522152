import clsx from 'clsx';
import { useFormik } from 'formik';
import { Eye, EyeSlash } from 'iconsax-react';
import React, { useState } from 'react';
import * as yup from 'yup';
import { confirmPasswordSchema, passwordSchema } from '../../utils/validationSchema';
import ErrorText from '../common/ErrorText';
import DefaultModal from './DefaultModal';

const ChangePasswordModal = ({ props }) => {
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: yup.object({
            oldPassword: passwordSchema,
            newPassword: passwordSchema,
            confirmPassword: confirmPasswordSchema('newPassword')
        }),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        console.log(values);
        return true;
    }

    return (
        <DefaultModal
            title="Change Password"
            handleSubmit={handleSubmit}
            submittable={formik.dirty && formik.isValid}
        >
            <div className="flex flex-col gap-y-1.5">
                <label htmlFor="oldPassword" className="text-white/60 font-medium text-sm">Old Password</label>
                <div className="relative">
                    <input
                        type={showOldPassword ? 'text' : 'password'}
                        id="oldPassword"
                        name="oldPassword"
                        autoComplete="off"
                        placeholder="Enter old password"
                        className={clsx(
                            "w-full placeholder:text-white/40 text-sm bg-transparent border-2 border-white/5 p-3 rounded-lg font-normal placeholder:font-light focus:border-white/10 pr-12"
                        )}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.oldPassword}
                    />

                    <button
                        type="button"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white"
                    >
                        {showOldPassword ? <Eye size={20} /> : <EyeSlash size={20} />}
                    </button>
                </div>
                <ErrorText error={formik?.touched?.oldPassword && formik?.errors?.oldPassword} />

                <label htmlFor="newPassword" className="text-white/60 font-medium text-sm mt-4">New Password</label>
                <div className="relative">
                    <input
                        type={showNewPassword ? 'text' : 'password'}
                        id="newPassword"
                        name="newPassword"
                        autoComplete="off"
                        placeholder="Enter new password"
                        className={clsx(
                        "w-full placeholder:text-white/40 text-sm bg-transparent border-2 border-white/5 p-3 rounded-lg font-normal placeholder:font-light focus:border-white/10"
                    )}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                        value={formik.values.newPassword}
                    />

                    <button
                        type="button"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white"
                    >
                        {showNewPassword ? <Eye size={20} /> : <EyeSlash size={20} />}
                    </button>
                </div>
                <ErrorText error={formik?.touched?.newPassword && formik?.errors?.newPassword} />

                <label htmlFor="confirmPassword" className="text-white/60 font-medium text-sm mt-4">Confirm New Password</label>
                <div className="relative">
                    <input
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        name="confirmPassword"
                        autoComplete="off"
                        placeholder="Confirm new password"
                        className={clsx(
                        "w-full placeholder:text-white/40 text-sm bg-transparent border-2 border-white/5 p-3 rounded-lg font-normal placeholder:font-light focus:border-white/10"
                    )}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                        value={formik.values.confirmPassword}
                    />

                    <button
                        type="button"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white"
                    >
                        {showConfirmPassword ? <Eye size={20} /> : <EyeSlash size={20} />}
                    </button>
                </div>
                <ErrorText error={formik?.touched?.confirmPassword && formik?.errors?.confirmPassword} />
            </div>
        </DefaultModal>
    );
};

export default ChangePasswordModal;
