import React from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Copy } from "iconsax-react";
import toast from "react-hot-toast";

const items = [
    {
        date: moment().unix(),
        asset_symbol: "TRY",
        asset_name: "Turkish Lira",
        amount: 100,
        status: "Success",
    }
]

const RecentDeposits = () => {
    return (
        <div className="flex flex-col gap-y-4 mt-24">
            <h2 className="text-white text-xl font-semibold border-b-2 pb-1 mb-2 w-fit">Recent Deposits</h2>

            <table className="w-full text-left font-light text-sm">
                <thead>
                    <tr className="text-white/40">
                        <th className="font-normal px-5 py-2 bg-white/5 rounded-tl">Date</th>
                        <th className="font-normal px-5 py-2 bg-white/5">Asset</th>
                        <th className="font-normal px-5 py-2 bg-white/5">Amount</th>
                        <th className="font-normal px-5 py-2 bg-white/5 rounded-tr text-right">Status</th>
                    </tr>
                </thead>
                <tbody className="text-white/80">
                    {items.map((item, index) => (
                        <tr key={index} className="border-b border-white/5">
                            <td className="px-5 py-4">
                                {moment.unix(item.date).format('DD/MM/YYYY')}
                                <br />
                                {moment.unix(item.date).format('HH:mm')}
                            </td>
                            <td className="px-5 py-4">{item.asset_name} ({item.asset_symbol})</td>
                            <td className="px-5 py-4">{item.amount}</td>
                            <td className="px-5 py-4 text-right text-green-400">{item.status}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
};

export default RecentDeposits;
