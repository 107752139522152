import React from 'react';
import ChangeEmailModal from './ChangeEmailModal';
import useModalStore from '../../store/modalStore';
import ChangePhoneNumberModal from './ChangePhoneNumberModal';
import ChangePasswordModal from './ChangePassword';

const ModalManager = () => {
    const { modalType, modalProps } = useModalStore();

    return (
        <>
            {modalType === 'changeEmail' && <ChangeEmailModal props={modalProps} />}
            {modalType === 'changePhoneNumber' && <ChangePhoneNumberModal props={modalProps} />}
            {modalType === 'changePassword' && <ChangePasswordModal props={modalProps} />}
        </>
    )
}

export default ModalManager;