import React from "react";
import { Link } from "react-router-dom";
import CustomContainer from "../../common/CustomContainer";
import { Youtube } from "iconsax-react";

const Footer = () => {
    return (
        <div className="flex flex-col mt-24">
            <div className="flex flex-col w-full text-left bg-black border-t border-white/5 py-12">
                <CustomContainer className="flex flex-col lg:flex-row gap-6 lg:gap-0">
                    <div className="flex flex-col gap-y-4 lg:max-w-64 xl:max-w-80">
                        <img
                            src="img/logo.png"
                            alt="Quick Crypto"
                            className="w-12 h-auto"
                        />
                        <span className="text-white/60">Quick Crypto is a platform that allows you to make secure, fast crypto payments from your wallet or exchange without wallet setup and access.</span>
                    </div>

                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-12 gap-y-8 lg:ml-auto text-white/60">
                        <div className="flex flex-col gap-y-4">
                            <h3 className="text-lg text-white font-medium">Company</h3>
                            <Link to="/" className="hover:text-white w-fit">Home</Link>
                            <a href="/#why-us" className="hover:text-white w-fit">Why Us</a>
                            <a href="/#campaigns" className="hover:text-white w-fit">Campaigns & Coins</a>
                            {/* <a href="/#methods" className="hover:text-white w-fit">Methods</a> */}
                        </div>
                        <div className="flex flex-col gap-y-4">
                            <h3 className="text-lg text-white font-medium">Personal uses</h3>
                            <Link to="/" className="hover:text-white w-fit !opacity-30 hover:cursor-not-allowed">Wallet</Link>
                            <Link to="/" className="hover:text-white w-fit !opacity-30 hover:cursor-not-allowed">Deposit</Link>
                            <Link to="/" className="hover:text-white w-fit !opacity-30 hover:cursor-not-allowed">Withdraw</Link>
                            <Link to="/" className="hover:text-white w-fit !opacity-30 hover:cursor-not-allowed">How to use</Link>
                            <Link to="/" className="hover:text-white w-fit !opacity-30 hover:cursor-not-allowed">Fees</Link>
                        </div>
                        <div className="flex flex-col gap-y-4">
                            <h3 className="text-lg text-white font-medium">Business use</h3>
                            <Link to="/" className="hover:text-white w-fit !opacity-30 hover:cursor-not-allowed">Whitelabel</Link>
                            <Link to="/" className="hover:text-white w-fit !opacity-30 hover:cursor-not-allowed">API</Link>
                        </div>
                        <div className="flex flex-col gap-y-4">
                            <h3 className="text-lg text-white font-medium">Support</h3>
                            <Link to="/contact" className="hover:text-white w-fit">Contact Us</Link>
                        </div>
                    </div>
                </CustomContainer>
            </div>

            <div className="w-full text-left bg-black border-t border-white/5 py-5 text-sm">
                <CustomContainer className="flex flex-col lg:flex-row items-center justify-between gap-y-4 lg:gap-0">
                    <div className="flex items-center gap-x-4">
                        <span>Ⓒ 2024 Quick Crypto. All Rights Reserved</span>
                        {/* <Link to="/" className="hover:text-white">
                            <Youtube variant="Bold" />
                        </Link> */}
                    </div>

                    {/* <div className="flex items-center justify-end gap-x-4">
                        <Link to="/" className="hover:text-white">Privacy Policy</Link>
                        <Link to="/" className="hover:text-white">Terms of Use</Link>
                    </div> */}
                </CustomContainer>
            </div>
        </div >
    );
};

export default Footer;