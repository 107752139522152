import React from 'react';
import DefaultModal from './DefaultModal';
import clsx from 'clsx';
import { useFormik } from 'formik';
import { emailSchema } from '../../utils/validationSchema';
import ErrorText from '../common/ErrorText';
import * as yup from 'yup';

const ChangeEmailModal = ({ props }) => {
    const formik = useFormik({
        initialValues: {
            email: ''
        },
        validationSchema: yup.object({
            email: emailSchema
        }),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        console.log(values);
        return true;
    }

    return (
        <DefaultModal
            title={props?.type === 'change' ? 'Change Email' : 'Verify Email'}
            handleSubmit={handleSubmit}
            submittable={formik.dirty && formik.isValid}
        >
            <div className="flex flex-col gap-y-1.5">
                <label htmlFor="email" className="text-white/60 font-medium text-sm">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    autoComplete="off"
                    placeholder={props?.type === 'change' ? "Enter new email" : "Enter email"}
                    className={clsx(
                        "w-full placeholder:text-white/40 text-sm bg-transparent border-2 border-white/5 p-3 rounded-lg font-normal placeholder:font-light focus:border-white/10"
                    )}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                />
                <ErrorText error={formik?.touched?.email && formik?.errors?.email} />
            </div>
        </DefaultModal>
    );
};

export default ChangeEmailModal;