import clsx from "clsx";
import React from "react";

const CustomContainer = ({ children, className, ...rest }) => {
    return (
        <div className={clsx("w-full max-w-[90%] 2xl:max-w-screen-xl mx-auto", className)} {...rest}>
            {children}
        </div>
    );
};

export default CustomContainer;