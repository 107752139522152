export const storeForceFetchUtil = (data, loading, forceFetch = false) => {
    if (!forceFetch && data) return data;

    return new Promise((resolve) => {
        const interval = setInterval(() => {
            if (!loading) {
                clearInterval(interval);
                resolve(data);
            }
        }, 100);
    });
};