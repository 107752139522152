import { Add } from 'iconsax-react';
import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import useModalStore from '../../store/modalStore';
import clsx from 'clsx';

const DefaultModal = ({
    title,
    children,
    handleSubmit = () => { },
    submittable = false
}) => {
    const { closeModal } = useModalStore();

    const [loading, setLoading] = useState(false);

    const onClickSubmit = async () => {
        setLoading(true);
        const result = await handleSubmit();
        setLoading(false);

        if (result) closeModal();
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-[60]">
            <OutsideClickHandler onOutsideClick={closeModal} display='contents'>
                <div className="rounded-lg bg-[#191919] w-full max-w-md py-4">
                    <div className='flex items-center justify-between border-b border-white/10 px-6 pb-4'>
                        <h2 className="text-base font-bold">{title}</h2>
                        <button onClick={closeModal} className="text-white/60 hover:text-white">
                            <Add className="w-7 h-7 rotate-45" />
                        </button>
                    </div>

                    <div className='flex flex-col px-6 py-2'>
                        <div className="mt-2">{children}</div>
                    </div>

                    <div className="flex justify-center px-6 mt-4">
                        <button
                            className={clsx(
                                "text-white border border-white/10 hover:bg-white/5 w-full p-3 rounded-lg font-semibold",
                                (loading || !submittable) && "opacity-50 cursor-not-allowed !bg-white/5 !text-white/50"
                            )}
                            disabled={loading || !submittable}
                            onClick={onClickSubmit}
                        >
                            {loading ? 'Submitting...' : 'Submit'}
                        </button>
                    </div>
                </div>
            </OutsideClickHandler>
        </div>
    );
};

export default DefaultModal;