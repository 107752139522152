import moment from 'moment-timezone';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import DashboardLayout from './components/layout/dashboard/DashboardLayout';
import Layout from './components/layout/main/Layout';
import Dashboard from './pages/dashboard/Dashboard';
import Deposit from './pages/dashboard/Deposit';
import Settings from './pages/dashboard/Settings';
import Swap from './pages/dashboard/Swap';
import Withdraw from './pages/dashboard/Withdraw';
import Home from './pages/Home';
import CheckYourEmail from './pages/main/CheckYourEmail';
import Contact from './pages/main/Contact';
import Convert from './pages/main/Convert';
import ForgotPassword from './pages/main/ForgotPassword';
import SignIn from './pages/main/SignIn';
import SignUp from './pages/main/SignUp';
import VerifyOtp from './pages/main/VerifyOtp';

moment.tz.setDefault('Etc/UTC');
moment.locale('en');

function Index() {
  return (
    <BrowserRouter>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 3000,
          style: {
            background: 'black',
            color: 'white',
            fontSize: '16px',
            fontWeight: 'semibold',
          },
        }}
      />

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="contact" element={<Contact />} />
        </Route>

        {/* <Route path='/pay/:id' element={<Pay />} /> */}
        <Route path='/convert/:id' element={<Convert />} />
        {/* <Route path="/sign-in" element={<SignIn />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/check-your-email" element={<CheckYourEmail />} /> */}

        {/* <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="deposit" element={<Deposit />} />
          <Route path="withdraw" element={<Withdraw />} />
          <Route path="swap" element={<Swap />} />
          <Route path="settings" element={<Settings />} />
        </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Index />);