import { create } from 'zustand';
import { makeRequest } from '../helpers/requester';
import { storeForceFetchUtil } from '../utils/storeForceFetchUtil';

const useUserStore = create((set, get) => ({
    user: null,
    loading: false,
    signOut: () => {
        localStorage.removeItem("token");
        window.location.href = '/sign-in';
        set({ user: null });
    },
    fetchUser: async (forceFetch = false) => {
        const { user, loading } = get();
        if (loading || (user && !forceFetch)) return;

        if (!localStorage.getItem("token")) {
            set({ user: null, loading: false });
            return;
        }

        const data = storeForceFetchUtil(user, loading, forceFetch);
        set({ user: data, loading: true });

        try {
            const response = await makeRequest('get', '/get-user', {});
            set({ user: response?.user || null, loading: false });
        } catch (error) {
            set({ loading: false });
        }
    }
}));

export default useUserStore;