import React from "react";
import clsx from "clsx";

const Button = ({ variant, size, className, children, onClick, ...rest }) => {
    const baseClasses = "px-6 py-2.5 rounded-full font-semibold";
    const variantClasses = {
        primary: "bg-white text-black hover:-translate-y-1",
        secondary: "bg-transparent border border-white/10 text-white hover:opacity-80",
        circular: "bg-transparent border border-white/30 text-white hover:opacity-80 !rounded-full",
        success: "bg-emerald-700 text-white hover:bg-emerald-600"
    }[variant] || "";

    return (
        <button
            className={clsx(
                baseClasses,
                variantClasses,
                rest?.disabled && '!opacity-50 !pointer-events-none',
                className
            )}
            onClick={onClick}
            {...rest}
        >
            {children}
        </button>
    );
};

export default Button;