import { Bezier, TrendUp, Volume } from "iconsax-react";
import React from "react";
import CustomContainer from "../common/CustomContainer";

const HomeWhyUs = () => {
    return (
        <CustomContainer id="why-us">
            <h2 className="text-emerald-300 text-2xl lg:text-4xl font-bold mb-2 max-w-2xl text-center mx-auto mt-40">Better, Faster, Cheaper</h2>
            <p className="text-white text-base lg:text-lg mb-12 max-w-2xl text-center mx-auto">Quick Crypto has features not found in its competitors, such as the convert/fast swap feature for customers</p>

            <div className="grid grid-cols-1 md:grid-cols-3 mt-10">
                <div className="flex flex-col gap-y-3 text-left border-l border-b border-white/5 px-8 py-6">
                    <TrendUp size={40} className="text-emerald-300" />
                    <h3 className="text-white text-lg">Better</h3>
                    <p className="text-white/40 text-base">We noticed the shortcomings, high commission rates and slowness of our competitors, and we produced solutions.</p>
                </div>
                <div className="flex flex-col gap-y-3 text-left border-l border-b border-white/5 px-8 py-6">
                    <Volume size={40} className="text-emerald-300" />
                    <h3 className="text-white text-lg">Faster</h3>
                    <p className="text-white/40 text-base">We noticed the shortcomings, high commission rates and slowness of our competitors, and we produced solutions.</p>
                </div>
                <div className="flex flex-col gap-y-3 text-left border-l border-b border-white/5 px-8 py-6">
                    <Bezier size={40} className="text-emerald-300" />
                    <h3 className="text-white text-lg">Cheaper</h3>
                    <p className="text-white/40 text-base">We noticed the shortcomings, high commission rates and slowness of our competitors, and we produced solutions.</p>
                </div>
            </div>
        </CustomContainer>
    );
};

export default HomeWhyUs;