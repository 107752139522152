import { create } from 'zustand';

const useModalStore = create((set) => ({
    isOpen: false,
    modalType: null,
    modalProps: {},
    openModal: (type, props) => set({ isOpen: true, modalType: type, modalProps: props }),
    closeModal: () => set({ isOpen: false, modalType: null, modalProps: {} })
}));

export default useModalStore;