import React from "react";
import Button from "../common/Button";
import CustomContainer from "../common/CustomContainer";

const HomeHero = () => {
    return (
        <CustomContainer className="grid grid-cols-1 md:grid-cols-2 items-center justify-between md:pt-10 gap-y-10 text-center md:text-left">
            <div className="w-full flex-1 flex flex-col items-center md:items-start max-w-xl gap-y-6">
                <h1 className="text-3xl md:text-4xl lg:text-6xl text-white font-semibold">
                    Quick Crypto
                </h1>
                <h2 className="text-xl text-white/40 font-light !leading-[2.25rem]">
                    Enable secure, fast crypto payments from your wallet or exchange without wallet setup and access
                </h2>

                <Button
                    variant="primary"
                    className="text-lg mt-4 w-fit"
                    onClick={() => {
                        window.location.href = "/contact";
                    }}
                >
                    Contact Us
                </Button>
            </div>

            <img
                src="/img/convert-mobile.png"
                alt="hero"
                className="w-full max-w-80 ml-auto max-md:mr-auto"
            />
        </CustomContainer>
    );
};

export default HomeHero;