import clsx from "clsx";
import { useFormik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import ReactInputMask from "react-input-mask";
import * as yup from "yup";
import AlertBox from "../../components/common/AlertBox";
import Button from "../../components/common/Button";
import CustomContainer from "../../components/common/CustomContainer";
import ErrorText from "../../components/common/ErrorText";
import { emailSchema, firstNameSchema, lastNameSchema, messageSchema, phoneNumberSchema } from "../../utils/validationSchema";
import { Call, Location, Send, Send2 } from "iconsax-react";

const Contact = () => {
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: ''
        },
        validationSchema: yup.object({
            firstName: firstNameSchema,
            lastName: lastNameSchema,
            email: emailSchema,
            phone: phoneNumberSchema,
            message: messageSchema
        }),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            try {
                const subject = `Feedback for Mir4 Collection's Payment Gateway (Contact Form)`;
                const body = encodeURIComponent(
                    `Type: Contact Form\n` +
                    `First Name: ${values.firstName}\n` +
                    `Last Name: ${values.lastName}\n` +
                    `Email: ${values.email}\n` +
                    `Phone Number: ${values.phone}\n\n` +
                    `Message: ${values.message}`
                );
                const mailtoLink = `mailto:support@mir4collection.com?subject=${subject}&body=${body}`;
                window.location.href = mailtoLink;

                toast.success('We have formatted your message, just send it');
                formik.resetForm();
            } catch (error) {
                toast.error('Something went wrong');
            }
        },
    });

    return (
        <CustomContainer className="flex flex-col py-10">
            <div className="flex flex-col xl:flex-row">
                <div className="w-full flex-1 flex flex-col gap-y-4 mt-8 border-r border-white/5 pr-12">
                    <div className="flex items-center justify-between">
                        <h1 className="text-white text-xl font-semibold border-b-2 pb-1 mb-2">Contact Us</h1>
                    </div>

                    <div className="flex flex-col pl-4">
                        <div className="relative flex flex-col md:flex-row items-center gap-x-8 border-l border-white/10 border-dashed pl-8 pb-6">
                            <div className="absolute left-0 top-0 w-8 h-8 bg-white rounded-full -translate-x-1/2 text-black flex items-center justify-center">1</div>

                            <div className="flex flex-col gap-y-4 w-full">
                                <p className="text-white font-normal mt-1">First name</p>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={formik?.values?.firstName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="bg-transparent placeholder:text-white/40 font-normal placeholder:font-light focus:outline-none w-full border border-white/10 rounded-lg px-4 py-3"
                                    placeholder="Enter your first name"
                                />
                                <ErrorText error={formik?.touched?.firstName && formik?.errors?.firstName} />
                            </div>

                            <div className="flex flex-col gap-y-4 w-full">
                                <p className="text-white font-normal mt-1">Last name</p>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={formik?.values?.lastName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="bg-transparent placeholder:text-white/40 font-normal placeholder:font-light focus:outline-none w-full border border-white/10 rounded-lg px-4 py-3"
                                    placeholder="Enter your last name"
                                />
                                <ErrorText error={formik?.touched?.lastName && formik?.errors?.lastName} />
                            </div>
                        </div>

                        <div className={clsx(
                            "relative flex flex-col gap-y-4 border-l border-white/10 border-dashed pl-8 pb-6 opacity-30 pointer-events-none",
                            (formik?.values?.firstName && !formik?.errors?.firstName && formik?.values?.lastName && !formik?.errors?.lastName) && '!opacity-100 !pointer-events-auto'
                        )}>
                            <div className="absolute left-0 top-0 w-8 h-8 bg-white rounded-full -translate-x-1/2 text-black flex items-center justify-center">2</div>

                            <p className="text-white font-normal mt-1">Email</p>
                            <input
                                type="text"
                                name="email"
                                value={formik?.values?.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="bg-transparent placeholder:text-white/40 font-normal placeholder:font-light focus:outline-none w-full border border-white/10 rounded-lg px-4 py-3"
                                placeholder="Enter your email"
                            />
                            <ErrorText error={formik?.touched?.email && formik?.errors?.email} />
                        </div>

                        <div className={clsx(
                            "relative flex flex-col gap-y-4 border-l border-white/10 border-dashed pl-8 pb-6 opacity-30 pointer-events-none",
                            (formik?.values?.email && !formik?.errors?.email && formik?.values?.firstName && !formik?.errors?.firstName && formik?.values?.lastName && !formik?.errors?.lastName) && '!opacity-100 !pointer-events-auto'
                        )}>
                            <div className="absolute left-0 top-0 w-8 h-8 bg-white rounded-full -translate-x-1/2 text-black flex items-center justify-center">3</div>

                            <p className="text-white font-normal mt-1">Phone number</p>
                            <ReactInputMask
                                mask="+99 999 999 99 99"
                                maskChar=" "
                                type="text"
                                id="phone"
                                name="phone"
                                autoComplete="off"
                                placeholder="Enter phone number"
                                className="bg-transparent placeholder:text-white/40 font-normal placeholder:font-light focus:outline-none w-full border border-white/10 rounded-lg px-4 py-3"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                            />
                            <ErrorText error={formik?.touched?.phone && formik?.errors?.phone} />
                        </div>

                        <div className={clsx(
                            "relative flex flex-col gap-y-4 pl-8 pb-6 opacity-30 pointer-events-none",
                            (formik?.values?.phone && !formik?.errors?.phone && formik?.values?.email && !formik?.errors?.email && formik?.values?.firstName && !formik?.errors?.firstName && formik?.values?.lastName && !formik?.errors?.lastName) && '!opacity-100 !pointer-events-auto'
                        )}>
                            <div className="absolute left-0 top-0 w-8 h-8 bg-white rounded-full -translate-x-1/2 text-black flex items-center justify-center">4</div>

                            <p className="text-white font-normal mt-1">Message</p>
                            <div className="relative flex items-center">
                                <textarea
                                    name="message"
                                    value={formik?.values?.message}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className="bg-transparent placeholder:text-white/40 font-normal placeholder:font-light focus:outline-none w-full border border-white/10 rounded-lg px-4 py-3"
                                    placeholder="Enter your message"
                                    rows={5}
                                />
                            </div>
                            <ErrorText error={formik?.touched?.message && formik?.errors?.message} />

                            <div className="flex items-center justify-between">
                                <Button
                                    variant="primary"
                                    disabled={!formik.dirty || !formik.isValid}
                                    onClick={formik.handleSubmit}
                                    className="w-full"
                                >
                                    Send message
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col w-full max-w-xl pl-12 py-8 gap-y-12">
                    <div className="flex flex-col gap-y-2">
                        <h2 className="text-white text-xl font-normal">Chat with us</h2>
                        <p className="text-white/60">
                            If you have any questions or feedback, please contact us using the form or use the information below.
                        </p>

                        <div className="flex flex-col gap-y-2 text-white mt-2">
                            <div className="flex items-center gap-x-2">
                                <Send2 className="w-5 h-5" variant="Bold" />
                                <a href="mailto:support@mir4collection.com" className="underline">Send a mail for support or feedback</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomContainer>
    );
};

export default Contact;