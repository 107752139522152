import React from "react";
import HomeHero from "../components/main/HomeHero";
import HomeSlider from "../components/main/HomeSlider";
import HomePartnerships from "../components/main/HomePartnerships";
import HomeOurSystems from "../components/main/HomeOurSystems";
import { makeRequest } from "../helpers/requester";
import HomeWhyUs from "../components/main/HomeWhyUs";

const Home = () => {
    // const handleCreateOrder = async () => {
    //     try {
    //         const res = await makeRequest('post', '/orders', {
    //             "amount": 1,
    //             "currency": "USDT",
    //             "network": "BSC",
    //             "title": "Buy Wemix",
    //             "description": "You can complete your transaction by sending ... USsDT (BSC) to the address below. Once the transaction is completed, ... WEMIX will be automatically sent to the address you specified.",
    //             "type": "fast_swap",
    //             "to_address": "0x4560B3b466a28c812BC1F9855cB36764d7d3F28C",
    //             "to_currency": "WEMIX",
    //             "to_network": "WEMIX"
    //         }, {
    //             'X-SECRET-KEY': '5194a32c-e165c790-8cbb3808-fe4f268d'
    //         });

    //         if (res?.status === 'success' && res?.order?.payment_url) {
    //             // window.location.href = res?.order?.payment_url;
    //             window.open(res?.order?.payment_url, '_blank');
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    return (
        <div className="flex flex-col">
            {/* <button
                onClick={handleCreateOrder}
                className="bg-white/10 text-white px-12 py-4 rounded-md w-fit mx-auto  text-xl"
            >
                <span>Pay</span>
            </button> */}

            <HomeHero />
            <HomeWhyUs />
            <HomeSlider />
            <HomePartnerships />
            {/* <HomeOurSystems /> */}
        </div>
    );
};

export default Home;