import clsx from "clsx";
import React, { useEffect } from "react";
import CustomContainer from "../../common/CustomContainer";
import { Link, useNavigate } from "react-router-dom";
import useUserStore from "../../../store/userStore";
import Button from "../../common/Button";

const MobileDrawer = ({ isOpen, setIsOpen }) => {
    const { user, signOut, fetchUser } = useUserStore();

    const isSignedIn = user?.email ? true : false;

    // useEffect(() => {
    //     fetchUser();
    // }, [user, fetchUser]);

    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "auto";
    }, [isOpen]);

    const handleClose = () => {
        setIsOpen(false);
    };

    return (
        <div className={clsx(
            "fixed top-0 left-0 w-full h-full bg-[#0A0A0A] z-50 mt-20 border-t border-white/5 shadow-md shadow-white/50",
            isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        )}>
            <CustomContainer className="flex flex-col h-full py-4 gap-y-6 text-lg font-normal text-white">
                <a href="/" onClick={handleClose}>Home</a>
                <a href="/#why-us" onClick={handleClose}>Why Us</a>
                <a href="/#campaigns" onClick={handleClose}>Campaigns & Coins</a>
                <a href="/#methods" onClick={handleClose}>Methods</a>

                {isSignedIn ? (
                    <Button variant="secondary" onClick={signOut} className={'w-full'}>Sign Out</Button>
                ) : (
                    <div className="flex gap-4">
                        <Link to="/contact" onClick={handleClose}>
                            <Button variant="success" className={'w-full'}>Contact Us</Button>
                        </Link>
                        {/* <Link to="/sign-in" className="w-full">
                            <Button variant="secondary" className={'w-full'}>Sign In</Button>
                        </Link>
                        <Link to="/sign-up" className="w-full">
                            <Button variant="primary" className={'w-full'}>Sign Up</Button>
                        </Link> */}
                    </div>
                )}
            </CustomContainer>
        </div>
    );
};

export default MobileDrawer;
