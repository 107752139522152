import React from 'react';
import * as yup from 'yup';
import clsx from 'clsx';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import DefaultModal from './DefaultModal';
import ErrorText from '../common/ErrorText';
import { phoneNumberSchema } from '../../utils/validationSchema';

const ChangePhoneNumberModal = ({ props }) => {
    const formik = useFormik({
        initialValues: {
            phone: ''
        },
        validationSchema: yup.object({
            phone: phoneNumberSchema
        }),
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = async (values) => {
        console.log(values);
        return true;
    }

    const handleChange = (e) => {
        let value = e.target.value;
        const isBackspace = e.nativeEvent.inputType === 'deleteContentBackward';

        if (!isBackspace) {
            if (!value.startsWith('+')) value = `+${value}`;
            if (value.length === 3 || value.length === 7 || value.length === 11 || value.length === 14) value += ' ';
            if (value.length > 17) value = value.slice(0, 17);
        }

        formik.setFieldValue('phone', value);
    }

    console.log(formik.values.phone);

    return (
        <DefaultModal
            title={props?.type === 'change' ? 'Change Phone Number' : 'Verify Phone Number'}
            handleSubmit={handleSubmit}
            submittable={formik.dirty && formik.isValid}
        >
            <div className="flex flex-col gap-y-1.5">
                <label htmlFor="phone" className="text-white/60 font-medium text-sm">Phone Number</label>
                <InputMask
                    mask="+99 999 999 99 99"
                    maskChar=" "
                    type="text"
                    id="phone"
                    name="phone"
                    autoComplete="off"
                    placeholder={props?.type === 'change' ? "Enter new phone number" : "Enter phone number"}
                    className={clsx(
                        "w-full placeholder:text-white/40 text-sm bg-transparent border-2 border-white/5 p-3 rounded-lg font-normal placeholder:font-light focus:border-white/10"
                    )}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                />
                <ErrorText error={formik?.touched?.phone && formik?.errors?.phone} />
            </div>
        </DefaultModal>
    );
};

export default ChangePhoneNumberModal;
