import { ChartSuccess, Convertshape2, Copy, LanguageCircle, Send2, Timer, Timer1 } from "iconsax-react";
import React, { useEffect, useState } from "react";
import Accordion from "../../components/common/Accordion";
import AlertBox from "../../components/common/AlertBox";
import Button from "../../components/common/Button";
import CustomContainer from "../../components/common/CustomContainer";
import { makeRequest } from "../../helpers/requester";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import toast from "react-hot-toast";
import clsx from "clsx";

const Convert = () => {
    const { id } = useParams();
    const [order, setOrder] = useState(null);
    const [timer, setTimer] = useState(0);
    const [status, setStatus] = useState('normal'); // normal, timeout, success

    useEffect(() => {
        const interval = setTimeout(() => {
            console.log(timer);
            if (timer === 15) {
                fetchOrder();
                setTimer(0);
            } else {
                setTimer(timer + 1);
            }
        }, 1000);
        return () => clearTimeout(interval);
    }, [timer]); // eslint-disable-line

    const fetchOrder = async () => {
        const res = await makeRequest('get', `orders/${id}`);
        if (res.status === 'success') {
            setOrder(res.order);
            const isRejected = res?.order?.status === 'rejected';
            const isExpired = moment.duration((res.order.end_timestamp * 1000) - moment.now()).as('hours') < -0.5; // 1 hour
            const isTimeout = moment.duration((res.order.end_timestamp * 1000) - moment.now()).as('seconds') < 0; // 0 seconds
            const isConfirmed = res?.order?.status === 'confirmed';

            if (isRejected || isExpired) window.location.href = '/';
            if (isTimeout) setStatus('timeout');
            if (isConfirmed) setStatus('success');
        }
    };

    useEffect(() => {
        fetchOrder();
    }, [id]); // eslint-disable-line

    if (!order) {
        return <div>Loading...</div>;
    }


    // Show timeout error without form
    // if (moment.duration((endDate * 1000) - moment.now()).as('seconds') < 0) window.location.href = '/';

    return (
        <CustomContainer className={clsx("flex flex-col", status === 'normal' && 'py-10')}>
            {status === 'normal' && (
                <AlertBox message="Send your withdraw to the address below." submessage="Sending wrong currency will result in a loss of funds. If you have any questions, please contact our customer support or check our FAQ." />
            )}

            {status === 'normal' && (
                <div className="flex flex-col xl:flex-row">
                    <div className="w-full flex-1 flex flex-col gap-y-4 mt-8">
                        <div className="flex flex-col md:flex-row items-center justify-between ">
                            <h1 className="text-white text-xl font-semibold border-b-2 pb-1 mb-2">Convert {order.currency} to {order.to_currency}</h1>
                            <Button
                                variant="secondary"
                                className={'flex items-center gap-x-2 text-sm'}
                                onClick={() => {
                                    window.location.href = '/contact';
                                }}
                            >
                                <Send2 size={24} />
                                <span className="font-light">Customer Support</span>
                            </Button>
                        </div>

                        <div className="flex flex-col p-4 rounded-lg border border-white/10 gap-y-2 text-center">
                            <div className="flex items-center justify-between">
                                <img
                                    src="/img/logo.png"
                                    alt="Quick Crypto"
                                    className="w-12 h-auto"
                                />

                                <div className="bg-white/20 rounded-full flex items-center justify-center p-2">
                                    <LanguageCircle size={32} />
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row items-center justify-between max-w-xl mx-auto gap-y-6">
                                <div className="flex flex-col">
                                    <span className="text-white/80">{order.network} ({order.currency})</span>
                                    <h2 className="text-white text-4xl font-bold">{order.amount} {order.currency}</h2>
                                    <span className="text-white/30">(You will send)</span>
                                </div>

                                <Convertshape2 size={32} className="rotate-90 mx-8 text-white/40" />

                                <div className="flex flex-col">
                                    <span className="text-white/80">{order?.to_currency} ({order?.to_network})</span>
                                    <h2 className="text-white text-4xl font-bold">{order?.to_amount?.toString()?.slice(0, 6) || 0} {order?.to_currency}</h2>
                                    <span className="text-white/30">(You will receive)</span>
                                </div>
                            </div>

                            <div className="flex flex-col border-t border-[#2E2E2E] mt-4 pt-4">
                                <span className="text-white/40">Send to this address</span>
                                <div className="flex items-center justify-center gap-x-2 text-base mt-2">
                                    <span className="text-white/80 break-all">{order.address}</span>
                                    <button
                                        className="text-white/40 hover:text-white"
                                        onClick={() => {
                                            navigator.clipboard.writeText(order.address);
                                            toast.success('Copied address');
                                        }}>
                                        <Copy size={24} variant="Bold" />
                                    </button>
                                </div>

                                <img
                                    src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${order.address}`}
                                    alt="qr code"
                                    className="max-w-48 mx-auto mt-4 rounded-lg border border-white/10 p-2"
                                />

                            </div>

                            <div className="flex flex-col border-t border-[#2E2E2E] pt-4 gap-y-3 mt-2 text-sm">
                                <div className="flex items-center justify-between gap-y-1">
                                    {/* Alacağınız miktarın güncellenmesine kalan süre */}
                                    <span className="text-white/60">Time left to updating amount</span>
                                    <div className="flex items-center">
                                        <Timer size={24} className="text-white/40" />
                                        <span className="text-white w-20 text-right">
                                            {timer === 15 ? 'Updating...' : `${15 - timer} seconds`}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between gap-y-1">
                                    <span className="text-white/60">Time left to pay</span>
                                    <div className="flex items-center">
                                        <Timer1 size={24} className="text-white/40" />
                                        <span className="text-white w-11 text-right">
                                            {/* {moment.utc(moment.duration((order.end_timestamp * 1000) - moment.now()).asMilliseconds()).format('mm:ss')} */}
                                            {/* 7 minutes gibi yapalım */}
                                            {moment.utc(moment.duration((order.end_timestamp * 1000) - moment.now()).asMilliseconds()).format('mm:ss')}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row items-center justify-between gap-y-1">
                                    <span className="text-white/60">Your {order?.to_currency} wallet</span>
                                    <div className="flex items-center gap-x-1">
                                        <span className="text-white break-all">{order.to_address}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p className="text-white/40 text-sm">* Check your wallet address, if you entered it incorrectly, place the create again.</p>
                    </div>

                    {/* Success message */}
                    {/* <div className="w-full flex-1 flex flex-col gap-y-4 py-12">
                    <h1 className="text-white text-xl font-semibold">Success!</h1>
                    <p className="text-white/40 text-sm">Your order has been successfully completed.</p>
                </div> */}

                    <Accordion
                        title="Convert"
                        className="!max-w-xl px-12 py-8"
                        items={[
                            {
                                title: "How to pay?",
                                content: "The payment process in Quick Crypto is quite simple. Firstly, go to your crypto exchange or web3 wallet, then click the withdraw button. After that, you will select the network, enter the address or scan the QR code, and enter the amount. Finally, click the pay button. That's it!"
                            },
                            {
                                title: "How long does it take to convert?",
                                content: "This depends on the network you are paying to. Generally, it takes 5-30 seconds."
                            },
                            {
                                title: "What is the convert fee?",
                                content: "The convert commission is none. We don't charge any fee for the convert."
                            },
                            {
                                title: "My payment is not confirmed, what should I do?",
                                content: "If your payment is not confirmed, please contact our customer support or check our FAQ."
                            }
                        ]}
                    />
                </div>
            )}

            {status === 'success' && (
                <div className="min-h-screen h-full flex flex-col items-center justify-center max-w-lg mx-auto">
                    <div className="w-full flex flex-col border border-white/5 rounded-2xl px-8 py-12 shadow shadow-emerald-700 h-fit">
                        <ChartSuccess size={48} className="text-emerald-200" />
                        <h1 className="text-emerald-200 text-xl font-medium mt-4 mb-1">Payment completed successfully!</h1>
                        <p className="py-2 text-white/60">We have received your payment, it will reach your wallet within 1-10 minutes.</p>
                        {/* kullanıcının zamanına göre güncel zamanı göster */}
                        <time className="text-white/40">{moment(order?.completed_timestamp ? order?.completed_timestamp * 1000 : order?.end_timestamp * 1000).tz(moment.tz.guess()).format('DD-MM-YYYY HH:mm')}</time>

                        <div className="flex flex-col gap-y-2 border-t-4 border-white/5 border-dashed mt-6 pt-6 text-white/60 text-sm">
                            <h2 className="text-white font-medium mb-4 text-lg text-left">Transaction detail</h2>
                            <div className="flex items-center justify-between">
                                <span>Bill ID</span>
                                <div className="flex items-center gap-x-2">
                                    <span className="text-white">#BILL2024</span>
                                    <button
                                        className="text-white/40 hover:text-white"
                                        onClick={() => {
                                            navigator.clipboard.writeText('#BILL2024');
                                            toast.success('Copied Bill ID');
                                        }}>
                                        <Copy size={20} variant="Bold" />
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-between">
                                <span>You Paid</span>
                                <span className="text-white">{order?.amount} {order?.currency}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span>You Received</span>
                                <span className="text-white">{order?.to_amount?.toString()?.slice(0, 6) || 0} {order?.to_currency}</span>
                            </div>
                            <div className="flex items-center justify-between">
                                <span>Your Wallet Address:</span>
                                <div className="flex items-center gap-x-2">
                                    <span className="text-white break-all">{order?.to_address?.slice(0, 6)}...{order?.to_address?.slice(-4)}</span>
                                    <button
                                        className="text-white/40 hover:text-white"
                                        onClick={() => {
                                            navigator.clipboard.writeText(order?.to_address);
                                            toast.success('Copied Your Wallet Address');
                                        }}>
                                        <Copy size={20} variant="Bold" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <p className="mt-6 text-white/40 text-sm border-t-4 border-dashed border-white/5 pt-6 text-left">
                            * If you think there is a problem, you can contact us
                        </p>

                        <div className="grid grid-cols-2 gap-x-6 mt-4">
                            {order?.redirect_url && (
                                <button
                                    onClick={() => window.location.href = order?.redirect_url || ''}
                                    className="bg-emerald-600 hover:bg-emerald-700 text-white text-center px-6 py-3 rounded">
                                    <span className="font-semibold">Go Website</span>
                                </button>
                            )}

                            <button
                                className="border border-white/10 text-center px-6 py-3 rounded hover:text-black hover:bg-white"
                                onClick={() => {
                                    window.location.href = '/contact';
                                }}
                            >
                                <span className="font-medium">Customer Support</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {status === 'timeout' && (
                <div className="min-h-screen h-full flex flex-col items-center justify-center max-w-lg mx-auto">
                    <div className="w-full flex flex-col border border-white/5 rounded-2xl px-8 py-12 shadow shadow-rose-700 h-fit">
                        <h1 className="text-rose-500 text-xl font-medium mt-4 mb-1">Payment Timeout</h1>
                        <p className="py-2 text-white/60">Your payment session has timed out. Please initiate a new order. If you haven't made a payment within 30 minutes, it is normal to see this screen.</p>

                        <p className="mt-6 text-white/40 text-sm border-t-4 border-dashed border-white/5 pt-6 text-left">
                            * If you think there is a problem, you can contact us
                        </p>

                        <div className="grid grid-cols-2 gap-x-6 mt-4">
                            {/* <button className="bg-rose-600 hover:bg-rose-700 text-white text-center px-6 py-3 rounded">
                                <span className="font-semibold">Go Website</span>
                            </button> */}
                            <button
                                className="border border-white/10 text-center px-6 py-3 rounded hover:text-black hover:bg-white"
                                onClick={() => {
                                    window.location.href = '/contact';
                                }}
                            >
                                <span className="font-medium">Customer Support</span>
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </CustomContainer >
    );
};

export default Convert;
