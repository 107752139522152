import { Add, Element3 } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../common/Button";
import CustomContainer from "../../common/CustomContainer";
import MobileDrawer from "./MobileDrawer";
import clsx from "clsx";

const Navbar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const isSignedIn = !!token;

    const [isMobileDrawerOpen, setIsMobileDrawerOpen] = useState(false);

    // function signOut() {
    //     localStorage.removeItem("token");
    //     navigate("/sign-in");
    // }

    const [activeItemId, setActiveItemId] = useState(''); // home, why-us, campaigns

    useEffect(() => {
        const path = location.pathname;
        setActiveItemId(path.split('/')[1]);
        console.log(path);
        if (path !== '/') return;
        setActiveItemId('home');

        const homeSections = document.querySelectorAll('#why-us, #campaigns');
        const handleScroll = () => {
            const scrollY = window.scrollY + window.innerHeight / 2;
            if (scrollY < homeSections[0].offsetTop) {
                setActiveItemId('home');
                return;
            }

            const activeSection = Array.from(homeSections).reverse().find(section => 
                scrollY >= section.offsetTop
            );
            setActiveItemId(activeSection?.id);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    return (
        <>
            <MobileDrawer isOpen={isMobileDrawerOpen} setIsOpen={setIsMobileDrawerOpen} />

            <div className="sticky top-0 z-50 backdrop-blur border-b border-white/5 bg-[#0A0A0A]/60 mb-10">
                <CustomContainer className="flex justify-between items-center h-20">
                    <Link to="/" className="flex items-center gap-2">
                        <img
                            src="img/logo.png"
                            alt="Quick Crypto"
                            className="w-12 h-auto"
                        />

                        <h1 className="text-white text-2xl font-bold hidden md:block">Quick Crypto</h1>
                    </Link>

                    <div className="hidden md:flex items-center justify-end gap-4">
                        {
                            isSignedIn ? (
                                <div className="flex items-center gap-6 mr-4 text-white/40">
                                    <Link to="/dashboard/swap" className="hover:text-white/60">Swap</Link>
                                    <Link to="/dashboard/deposit" className="hover:text-white/60">Deposit</Link>
                                    <Link to="/dashboard/withdraw" className="hover:text-white/60">Withdraw</Link>
                                </div>
                            ) : (
                                <div className="flex items-center gap-6 mr-4 text-white/40 font-normal">
                                    <Link to="/" className={clsx("hover:text-white/60", activeItemId === 'home' && "!text-emerald-300")}>Home</Link>
                                    <a href="/#why-us" className={clsx("hover:text-white/60", activeItemId === 'why-us' && "!text-emerald-300")}>Why Us</a>
                                    <a href="/#campaigns" className={clsx("hover:text-white/60", activeItemId === 'campaigns' && "!text-emerald-300")}>Campaigns & Coins</a>
                                    {/* <a href="/#methods" className="hover:text-white/60">Methods</a>
                                    <a href="/#fees" className="hover:text-white/60">Fees</a>
                                    <a href="/#faq" className="hover:text-white/60">FAQ</a> */}
                                </div>
                            )}

                        {isSignedIn ? (
                            <Button variant="secondary" onClick={() => navigate('/dashboard')}>Dashboard</Button>
                        ) : (
                            <>
                                <Link to="/contact">
                                    <Button variant={activeItemId === 'contact' ? "primary" : "success"} className="">Contact Us</Button>
                                </Link>
                                {/* <Link to="/sign-in">
                                <Button variant="secondary">Sign In</Button>
                            </Link>
                            <Link to="/sign-up">
                                <Button variant="success">Sign Up</Button>
                            </Link> */}
                            </>
                        )}
                    </div>

                    <button className="md:hidden" onClick={() => setIsMobileDrawerOpen(!isMobileDrawerOpen)}>
                        {isMobileDrawerOpen ? (
                            <Add className="w-10 h-10 text-white rotate-45" />
                        ) : (
                            <Element3 className="w-8 h-8 text-white/60" />
                        )}
                    </button>
                </CustomContainer>
            </div>
        </>
    );
};

export default Navbar;