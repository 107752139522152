import axios from "axios";
import toast from "react-hot-toast";

export const dev = process.env.NODE_ENV === 'development';

export const instance = axios.create({
    baseURL: dev ? "http://localhost:2000/api/" : "https://api.quickcrypto.io/",
    timeout: 30000,
    headers: {},
});

instance.interceptors.request.use(
    function (config) {
        config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export const makeRequest = async (type, path, body, headers = {}) => {
    const res = instance[type](path, body, { headers })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error;
        });

    return res;
};

instance.interceptors.response.use(
    function (response) {
        return response.data;
    },
    function (error) {
        if (error?.message === 'Network Error') {
            toast.error('Please try again later. Maintenance is ongoing.');
            return Promise.reject({
                code: 500,
            });
        }

        const code = error?.status ?? error?.code;
        if (code === 401) {
            localStorage.removeItem('token');
            window.location.href = '/sign-in';
        }

        if (error?.response?.status === 429) {
            toast.error('Too many requests. Please try again later.');
        }

        return Promise.reject({
            code,
            message: error?.response?.data?.message || error?.message
        });
    }
);