import React from "react";
import Marquee from "react-fast-marquee";

const HomePartnerships = () => {
    const partners = [
        "https://s2.coinmarketcap.com/static/img/coins/128x128/825.png",
        "https://s2.coinmarketcap.com/static/img/coins/128x128/3408.png",
        "https://s2.coinmarketcap.com/static/img/coins/128x128/1027.png",
        "https://s2.coinmarketcap.com/static/img/coins/128x128/1839.png",
        "https://s2.coinmarketcap.com/static/img/coins/128x128/5426.png",
        "https://s2.coinmarketcap.com/static/img/coins/128x128/1958.png",
        "https://s2.coinmarketcap.com/static/img/coins/128x128/7548.png",
        "https://www.geckoterminal.com/_next/image?url=https%3A%2F%2Fcoin-images.coingecko.com%2Fcoins%2Fimages%2F35968%2Flarge%2FCrow.png%3F1710265161&w=64&q=75",
        "https://www.geckoterminal.com/_next/image?url=https%3A%2F%2Fcoin-images.coingecko.com%2Fcoins%2Fimages%2F28637%2Flarge%2Fwemix_dollar.png%3F1696527620&w=64&q=75"
    ]

    return (
        <div className="relative w-full max-w-screen-xl mx-auto overflow-hidden mt-16">
            <Marquee
                gradient={true}
                gradientWidth={50}
                speed={40}
                gradientColor="#0A0A0A"
                gradientDirection={1}
                autoFill={true}
            >
                {partners.map((item, index) => (
                    <img
                        key={index}
                        src={item}
                        alt="gold in wallet Market Watch"
                        className="h-14 w-auto mx-5 rounded-full"
                    />
                ))}
            </Marquee>
        </div>
    );
};

export default HomePartnerships;