import { InfoCircle } from "iconsax-react";


const AlertBox = ({ message, submessage = "" }) => {
    return (
        <div className="items-centerw-full h-full bg-[#191919] rounded-lg px-4 py-4 border border-white/10 flex  gap-x-4">
            <InfoCircle size={24} color="#cc9743" />
            <div className="flex flex-col gap-y-2">

                <p className="text-white text-lg font-semibold">
                    {message}
                </p>
                <p className="text-white/60 text-base">
                    {submessage && <p>{submessage}</p>}
                </p>
            </div>

        </div>
    );
};

export default AlertBox;